<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import _ from "lodash";

import NodeBlock from '@/components/journeys/node-block.vue';

import {FlowyNewBlock, Flowy, FlowyDragHandle } from "@hipsjs/flowy-vue";
import "@hipsjs/flowy-vue/dist/lib/flowy-vue.css";

import {blocks} from "../../../services/journeys";
import {
	journeyMethods
} from "@/state/helpers";

/**
 * Edit campaign Component
 */
export default {
	page: {
		title: "Journey",
		meta: [
			{
				name: "description",
				content: appConfig.description,
			},
		],
	},
	components: {
		Layout,
		PageHeader,
		NodeBlock,
		FlowyNewBlock,
		Flowy,
		FlowyDragHandle  
	},
	data() {
		return {
			title: "",
			items: [],

			squareYSize: "700px",
			minimized:false,

			holder: [],
			dragging: false,
			newDraggingBlock:false,
			blocks: blocks,
			nodes: [
				{
					id: '1',
					parentId: -1,
					nodeComponent: 'NodeBlock',
					data: {
						block:{
							"title" : "Trigger",
							"type" : "trigger",
							"icon" : "mdi-lightning-bolt-outline",
							"color": "#5e40bf",
							updateJourneyWorkflow: this.updateJourneyWorkflow
						},
					},
				},
			],
			journey:{}
		}
	},
	
	mounted() {
		const journeyId = this.$route.query?.id;
    this.title = journeyId ? '' : this.$t('journeys.new_journey')
    if(journeyId){
      let loader = this.$loading.show();
      this.getJourney(journeyId).then((journey)=>{
        this.journey = journey.data;
				this.nodes = this.journey.journey || this.nodes;
				this.title = this.journey.name;
				this.nodes.forEach(element => {
					element.data.block.updateJourneyWorkflow = this.updateJourneyWorkflow;
					element.data.block.updateABTestingNodes = this.updateABTestingNodes;
					element.data.block.updateConditionPathNodes = this.updateConditionPathNodes;
					element.data.block.updateReachabilityNodes = this.updateReachabilityNodes;
					element.data.block.removeNode = this.removeNode;
					element.data.block.journeyId = journeyId;
				});

			}).catch(()=>{
				this.$notify({ type: 'error', text: this.$t('journeys.get_journey_error'),title:  this.$t('journeys.title') });
			}).finally(()=>{
				loader.hide();
			})
		}
	},
	created() {
		
	},
	methods: {
		...journeyMethods,

    onDragStartNewBlock (event) {
      // contains all the props and attributes passed to demo-node
      const { props } = event
      this.newDraggingBlock = props;
    },
    // eslint-disable-next-line no-unused-vars
    onDragStopNewBlock (event) {
      this.newDraggingBlock = null;
    },
    // REQUIRED
    beforeMove ({ to, from }) {
      // called before moving node (during drag and after drag)
      // indicator will turn red when we return false
      // from is null when we're not dragging from the current node tree
      // eslint-disable-next-line no-console
      console.log('beforeMove', to, from);

      // we cannot drag upper parent nodes in this demo
      if (from && from.parentId === -1) {
        return false;
      }
      // we're adding a new node (not moving an existing one)
      if (from === null) {
        // we've passed this attribute to the demo-node
        if (this.newDraggingBlock['custom-attribute'] === false) {
          return false
        }
      }

      return true;
    },
    // REQUIRED
    beforeAdd ({ to, from }) {
      // called before moving node (during drag and after drag)
      // indicator will turn red when we return false
      // from is null when we're not dragging from the current node tree
      // eslint-disable-next-line no-console
      console.log('beforeAdd', to, from);

      // we've passed this attribute to the demo-node
      if (this.newDraggingBlock['custom-attribute'] === false) {
        return false
      }

      return true;
    },
    randomInteger () {
      return Math.floor(Math.random() * 10000) + 1;
    },
    generateId () {
      let id = this.randomInteger();
      // _.find is a lodash function
      while (_.find(this.nodes, { id }) !== undefined) {
        id = this.randomInteger();
      }
      return id;
    },
    addNode (_event) {
      const id = this.generateId();
      this.nodes.push({
        ..._event.node,
        id,
      });
    },
    remove (event) {
      // node we're dragging to
      const { node } = event

      // we use lodash in this demo to remove node from the array
      const nodeIndex = _.findIndex(this.nodes, { id: node.id });
      this.nodes.splice(nodeIndex, 1);
    },
    move (event) {
      // node we're dragging to and node we've just dragged
      const { dragged, to } = event;

      // change panentId to id of node we're dragging to
      dragged.parentId = to.id;

			this.updateJourneyWorkflow();
    },
    add (event) {
      // every node needs an ID
      const id = this.generateId();

			const node = event.node;
			node.data.block.updateJourneyWorkflow = this.updateJourneyWorkflow;
      node.data.block.removeNode = this.removeNode;
			// add to array of nodes
      this.nodes.push({
        id,
        ...node,
      });

			if(event.node.data?.block?.type == 'flow_control_condition_simple'){
				// every node needs an ID
				const idYes = this.generateId();
				const idNo = this.generateId();

				const yesNode = {
					parentId : id,
					id: idYes,
					nodeComponent: "NodeBlock",
					data: {
						block: {
							type: "flow_control_condition_simple_child",
							value: 'true',
							title: this.$t('common.yes')
						}
					}
				}

				const noNode = {
					parentId : id,
					id: idNo,
					nodeComponent: "NodeBlock",
					data: {
						block: {
							type: "flow_control_condition_simple_child",
							value: 'false',
							title: this.$t('common.no')
						}
					}
				}

				this.nodes.push({
					...yesNode,
				});

				this.nodes.push({
					...noNode,
				});

			}else if(event.node.data?.block?.type == 'flow_control_ab_testing'){
				node.data.block.updateABTestingNodes = this.updateABTestingNodes;
				this.addABTestingNodes(id, node);
			}else if(event.node.data?.block?.type == 'flow_control_condition_complex'){
				node.data.block.updateConditionPathNodes = this.updateConditionPathNodes;
				this.addConditionPathsNodes(id,node);
			}else if(event.node.data?.block?.type == 'flow_control_reachability'){
				node.data.block.updateReachabilityNodes = this.updateReachabilityNodes;
				this.addReachabilityPathsNodes(id,node);
			}

			this.updateJourneyWorkflow();
		},
    // eslint-disable-next-line no-unused-vars
    onDragStart (event) {
      this.dragging = true;
    },

		onMinimizeorMaximizeClicked(){
			this.minimized = !this.minimized;
      this.squareYSize = this.minimized ? '35px' : '700px'
		},

		onSaveClicked(){
			
		},
		removeNode(node){
			const nodeIndex = _.findIndex(this.nodes, { id: node.id });
      this.nodes.splice(nodeIndex, 1);

			const children = this.nodes.filter(n=> n.parentId == node.id );

			children.forEach((n)=>{
				this.removeChild(n);
			})

			this.updateJourneyWorkflow();
		},
		removeChild(node){
			const nodeIndex = _.findIndex(this.nodes, { id: node.id });
      this.nodes.splice(nodeIndex, 1);

			const children = this.nodes.filter(n=> n.parentId == node.id );

			children.forEach((n)=>{
				this.removeChild(n);
			})

		},
		updateJourneyWorkflow(){
			const journey = {
				_id: this.journey._id,
				journey: this.nodes}
			
				this.updateJourney(journey).then(()=>{}).catch(()=>{
          this.$notify({ type: 'error', text: this.$t('journeys.update_journey_error'),title:  this.$t('journeys.title') });
        }).finally(()=>{
          
        })
		},
		removeABTestingNodes(childs,block){
			const toRemove = [];
			childs.forEach((c)=>{
				if(block.config.variants.filter(v=> v.id == c.id).length == 0){
					toRemove.push(c);
				}
			})

			const idSet = new Set(toRemove.map(item => item.id));
			this.nodes = this.nodes.filter(item => !idSet.has(item.id));
		},
		removeReachabilityNodes(children,block){
			const toRemove = [];
			children.forEach((c)=>{
				if(!c.data.block.code && block.config.values.filter(v=> v.id == c.id).length == 0){
					toRemove.push(c);
				}
			})

			const idSet = new Set(toRemove.map(item => item.id));
			this.nodes = this.nodes.filter(item => !idSet.has(item.id));
		},
		removeConditionPathNodes(children,block){
			const toRemove = [];
			children.forEach((c)=>{
				if(!c.data.block.code && block.config.values.filter(v=> v.id == c.id).length == 0){
					toRemove.push(c);
				}
			})

			const idSet = new Set(toRemove.map(item => item.id));
			this.nodes = this.nodes.filter(item => !idSet.has(item.id));
		},
		updateConditionPathNodes(block, parentId){
			const children = this.nodes.filter(f=>f.parentId == parentId);
			if(block.config.values.length < children.length -1){
				this.removeConditionPathNodes(children,block)
			}
			if(block.config.values.length > 0){
				block.config.values.forEach((v)=>{
					const node = this.nodes.filter(n=>v.id == n.id);

					if(node && node.length > 0){
						node[0].data.block.title = v.name;
					}else{
						const newpathid = this.generateId();

						const newpathNode = {
							parentId : parentId,
							id: newpathid,
							nodeComponent: "NodeBlock",
							data: {
								block: {
									type: "flow_control_complex_child",
									title: v.name,
								}
							}
						}
						this.nodes.push(newpathNode);
						v.id = newpathid;
					}
				});
			}
			this.orderConditionPaths(block.config.values);
		},
		orderConditionPaths(values){
			const idSet = new Set(values.map(item => item.id));

			this.nodes = this.nodes.sort((a, b) => {
					const orderA = idSet.has(a.id) ? values.findIndex(item => item.id === a.id) : this.nodes.indexOf(a);
					const orderB = idSet.has(b.id) ? values.findIndex(item => item.id === b.id) : this.nodes.indexOf(b);
				return orderA - orderB;
			});
		},
		updateABTestingNodes(block, parentId){
			const childs = this.nodes.filter(f=>f.parentId == parentId);
			if(block.config.variants.length != childs.length){
				this.removeABTestingNodes(childs,block)
			}
			if(block.config.variants.length > 0){
				block.config.variants.forEach((v)=>{
					const node = this.nodes.filter(n=>v.id == n.id);
					if(node && node.length > 0){
						node[0].data.block.title = v.name;
						node[0].data.block.ratio = v.ratio;
					}else{
						const newVariantId = this.generateId();

						const newVariantNode = {
							parentId : parentId,
							id: newVariantId,
							nodeComponent: "NodeBlock",
							data: {
								block: {
									type: "flow_control_variant_child",
									title: v.name,
									ratio: v.ratio,
								}
							}
						}
						this.nodes.push(newVariantNode);
						v.id = newVariantId;
					}
				})
			}
			
		},
		updateReachabilityNodes(block, parentId){
			const childs = this.nodes.filter(f=>f.parentId == parentId);
			if(block.config.values.length != childs.length){
				this.removeReachabilityNodes(childs,block)
			}

			block.config.values.forEach((v)=>{
					const node = this.nodes.filter(n=>v.id == n.id);

					if(node && node.length > 0){
						node[0].data.block.title = v.name;
					}else{
						const newpathid = this.generateId();

						const newpathNode = {
							parentId : parentId,
							id: newpathid,
							nodeComponent: "NodeBlock",
							data: {
								block: {
									type: "flow_control_reachability_child",
									title: v.name,
								}
							}
						}
						this.nodes.push(newpathNode);
						v.id = newpathid;
					}
			});
				
			this.orderConditionPaths(block.config.values);
		},
		addConditionPathsNodes(id,node){
			const path1 = this.generateId();
			const anythingElse = this.generateId();

			const path1Node = {
					parentId : id,
					id: path1,
					nodeComponent: "NodeBlock",
					data: {
						block: {
							type: "flow_control_complex_child",
							title: "Path 1",
						}
					}
				}

				const anythingElseNode = {
					parentId : id,
					id: anythingElse,
					nodeComponent: "NodeBlock",
					data: {
						block: {
							type: "flow_control_complex_child",
							title: this.$t('journeys.journey_workflow_control_anything_else'),
							code: "anything_else"
						}
					}
				}

				this.nodes.push({
					...path1Node,
				});

				this.nodes.push({
					...anythingElseNode,
				});

				node.data.block.config = {
					values: [
						{
							name: "Path 1",
							id: path1,
							type: "and",
							children:[]
						},
					]
				}

		},
		addReachabilityPathsNodes(id, node){
			const path1 = this.generateId();
			const anythingElse = this.generateId();

			const path1Node = {
					parentId : id,
					id: path1,
					nodeComponent: "NodeBlock",
					data: {
						block: {
							type: "flow_control_reachability_child",
							title: "Path 1",
						}
					}
				}

				const anythingElseNode = {
					parentId : id,
					id: anythingElse,
					nodeComponent: "NodeBlock",
					data: {
						block: {
							type: "flow_control_reachability_child",
							title: this.$t('journeys.journey_workflow_control_not_reachable'),
							code: "not_reachable"
						}
					}
				}

				this.nodes.push({
					...path1Node,
				});

				this.nodes.push({
					...anythingElseNode,
				});

				node.data.block.config = {
					values: [
						{
							name: "Path 1",
							id: path1,
						},
					]
				}
		},
		addABTestingNodes(id, node){
			
			const variantA = this.generateId();
			const variantB = this.generateId();

				const variantANode = {
					parentId : id,
					id: variantA,
					nodeComponent: "NodeBlock",
					data: {
						block: {
							type: "flow_control_variant_child",
							title: "Variant 1",
							ratio: 0
						}
					}
				}

				const variantBNode = {
					parentId : id,
					id: variantB,
					nodeComponent: "NodeBlock",
					data: {
						block: {
							type: "flow_control_variant_child",
							title: "Variant 2",
							ratio: 0
						}
					}
				}

				this.nodes.push({
					...variantANode,
				});

				this.nodes.push({
					...variantBNode,
				});

				node.data.block.config = {
					variants: [
						{
							name: "Variant 1",
							ratio: 0,
							id: variantA
						},
						{
							name: "Variant 2",
							ratio: 0,
							id: variantB
						},
					]
				}
		}
  },
	watch:{}
};
</script>

<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<div>
			<div class="full-container">
				<div
					id="dragme"
					class="draggable-square"
					:style="{ width: '250px', height: squareYSize, backgroundColor: 'white' }"
					ref="square">
					<div class="row mx-auto border-bottom build bg-primary bg-soft interact" v-on:click="onMinimizeorMaximizeClicked">
						<p class="col mb-0 text-primary font-size-14 fw-bold">{{$t('journeys.journey_workflow_components')}}</p>
						<i class="bx bx-minus col-2 text-end min" v-if="!minimized"></i>
						<i class="bx bx-plus col-2 text-end min"  v-if="minimized"></i>
					</div>
					<div v-if="!minimized" class="d-flex flex-column">
						<div v-for="(block,index) in blocks" :key="block.group">
							<label class="ps-2 mb-0" :class="index==0 ? 'pt-2' : 'pt-3'">{{block.group}}</label>
							<flowy-new-block
								v-for="(b,i) in block.blocks"
								:key="i"
								class="me-0"
								@drag-start="onDragStartNewBlock"
								@drag-stop="onDragStopNewBlock">
								<template v-slot:preview="{}">
									<FlowyDragHandle>
										<div class="p-2 block-preview">
										<i class="mdi" :class="b.preview.icon"></i> {{b.preview.title}}
									</div>
									</FlowyDragHandle>
								</template>
								<template v-slot:node="{}">
									<NodeBlock
										:block="b.node">
									</NodeBlock>
								</template>
							</flowy-new-block>
						</div>
					</div>
				</div>
					
				<flowy
						class="flow-panel"
						:nodes="nodes"
						:beforeMove="beforeMove"
						:beforeAdd="beforeAdd"
						@add="add"
						@move="move"
						@remove="remove"
						@drag-start="onDragStart">
				</flowy>
			</div>
  </div>
</Layout>
</template>

<style>

.build{
	display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
}

.full-container{
	position: relative;
	height: 700px;
	background-attachment: local;
	background-image: url(https://t3.ftcdn.net/jpg/02/58/04/22/360_F_258042265_dZVn2faJl8LdX7ARGySJMtn1XcmLih5B.jpg);
	background-repeat: repeat;
	background-size: 40px;
	background-position: center;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}

.flow-panel{
	display: flex;
	justify-content: center;
	margin: auto;
	padding-top: 15px;
	overflow: scroll !important;
  height: 700px;
}
.flowy-drag-handle button {
  cursor: grab;
}

.flowy-block.draggable-mirror {
  opacity: 0.6;
}

.draggable-square {
  position: absolute;
  user-select: none; /* Disable text selection while dragging */
  z-index: 1000;
	border: 1px solid;
	border-color: #ebecf2;
	border-radius: 4px;
	overflow-y: auto;
	max-height: calc(100% - 45px);
}

.min{
  font-weight: bold;
  padding-right: 7px;
  cursor: pointer;
}

.block-preview{
	border-width: 1px;
  border-style: solid;
  border-color: #ebecf2;
	margin: 5px 7px;
  border-radius: 8px;
	cursor: grab;
}

</style>